import { default as articlesh9E8fquLDRMeta } from "/opt/build/repo/frontend/pages/articles.vue?macro=true";
import { default as indexdATqU9DngJMeta } from "/opt/build/repo/frontend/pages/index.vue?macro=true";
import { default as pageZB1m7v8C5YMeta } from "~/strapi-pages/page.vue?macro=true";
import { default as blogPostlYsAxC8GClMeta } from "~/strapi-pages/blogPost.vue?macro=true";
import { default as caseHuIuXghG5PMeta } from "~/strapi-pages/case.vue?macro=true";
import { default as landingPage3xsCkfWs9IMeta } from "~/strapi-pages/landingPage.vue?macro=true";
export default [
  {
    name: articlesh9E8fquLDRMeta?.name ?? "articles",
    path: articlesh9E8fquLDRMeta?.path ?? "/articles",
    meta: articlesh9E8fquLDRMeta || {},
    alias: articlesh9E8fquLDRMeta?.alias || [],
    redirect: articlesh9E8fquLDRMeta?.redirect,
    component: () => import("/opt/build/repo/frontend/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: indexdATqU9DngJMeta?.name ?? "index",
    path: indexdATqU9DngJMeta?.path ?? "/",
    meta: indexdATqU9DngJMeta || {},
    alias: indexdATqU9DngJMeta?.alias || [],
    redirect: indexdATqU9DngJMeta?.redirect,
    component: () => import("/opt/build/repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "automation-digitalization-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/automation-digitalization-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":462,"category":"","documentId":"kaulz0tueg1hjfl3lqelomwm"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "systems",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/systems/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":357,"category":"temperature-monitoring","documentId":"rypgiqk1ln4oube9bogypphe"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "cases",
    path: pageZB1m7v8C5YMeta?.path ?? "/cases/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":409,"category":"","documentId":"h3y4nzd9zsjn0cu1v1r59wuh"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-calibration",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-calibration/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":412,"category":"","documentId":"k3eetxa7yjes1ksm1edwdza4"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "regulations-temperature-compliance",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":414,"category":"","documentId":"u7ca1f2v3guei9dnsq7wcqra"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mapping-data-logger-calculator",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mapping-data-logger-calculator/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":341,"category":"temperature-mapping","documentId":"vlbns97h6xdo0b5l297yjc6k"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "continuous-validation-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/continuous-validation-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":345,"category":"temperature-mapping","documentId":"sgy2hol7il5gx902ty4v4i0u"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "pharma",
    path: pageZB1m7v8C5YMeta?.path ?? "/industries/pharma/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":51,"category":"industries","documentId":"x0kioau0my22a3hxdm01thir"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "data-logger-placement-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/data-logger-placement-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":520,"category":"temperature-mapping","documentId":"gaoxi32210yinoj3d87zn6td"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "webinars",
    path: pageZB1m7v8C5YMeta?.path ?? "/webinars/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":521,"category":"","documentId":"uys5ozlf71z84zxn0k9dsh4n"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "privacy",
    path: pageZB1m7v8C5YMeta?.path ?? "/privacy/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":13,"category":"","documentId":"ljv5m1fy3earzqcklnov628l"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "incubators",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/incubators/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":48,"category":"data-logger","documentId":"umtg2ptavscf6u1gwjttrlpy"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "monitoring-price",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/monitoring-price/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":22,"category":"temperature-monitoring","documentId":"ib9mt2k2cvmrks72ixoi3ea0"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "guides",
    path: pageZB1m7v8C5YMeta?.path ?? "/guides/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":459,"category":"","documentId":"f76uxwdc31wf118ka8hsduz6"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "summer-mapping-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/summer-mapping-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":79,"category":"regulations-temperature-compliance","documentId":"g1z241m50u0ls6seocvj50k3"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "pharmacies",
    path: pageZB1m7v8C5YMeta?.path ?? "/industries/pharmacies/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":50,"category":"industries","documentId":"v66tlhp35l35pvz7u1j4tpu3"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "refrigerators",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/refrigerators/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":47,"category":"data-logger","documentId":"wvhhfeg4q5z3yf3ha4oltt6s"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "risk-based-mapping",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/risk-based-mapping/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":102,"category":"temperature-mapping","documentId":"alrc0m56dzj9jejtdytn1ee9"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-calibration-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-calibration/our-calibration-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":16,"category":"temperature-calibration","documentId":"gbzvfi96fbzxrq3nqacmpeul"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-compliance-audit-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/temperature-compliance-audit-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":39,"category":"regulations-temperature-compliance","documentId":"zb1zbcoxgvrvjx1agby9lq9e"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "report-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/report-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":40,"category":"temperature-mapping","documentId":"b5o5jtzred7jq2mj1o3zb8pm"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "liebherr-refrigerators",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/liebherr-refrigerators/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":44,"category":"data-logger","documentId":"izal6txb2ioojeqxpy2xkztq"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "continuous-mapping-infographic",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/continuous-mapping-infographic/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":486,"category":"temperature-mapping","documentId":"u1sxs5hi0mtd09p2ozo89w92"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "freezers",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/freezers/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":45,"category":"data-logger","documentId":"by8xtq1egie5j0d7di2nuwdm"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "careers",
    path: pageZB1m7v8C5YMeta?.path ?? "/careers/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":5,"category":"","documentId":"hvd6a277yp47uq17j3z3opyi"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thanks",
    path: pageZB1m7v8C5YMeta?.path ?? "/thanks/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":78,"category":"","documentId":"qdgmqeizemj1w2rv0srdtrig"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "default-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/default-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":57,"category":"","documentId":"ha39yzs30yz90ofycpd6wq9t"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "about-us",
    path: pageZB1m7v8C5YMeta?.path ?? "/about-us/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":4,"category":"","documentId":"qtxrd90zrr72mz2tr6l03iei"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "setup",
    path: pageZB1m7v8C5YMeta?.path ?? "/setup/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":86,"category":"","documentId":"x37u6x0zq1a8ffmywvf91umt"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "URS-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/URS-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":522,"category":"temperature-mapping","documentId":"ks1wnovl2x4bbgzhu44rf6mf"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "product-catalog",
    path: pageZB1m7v8C5YMeta?.path ?? "/product-catalog/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":29,"category":"","documentId":"hfx93ptfg37n6asmcwnwggc4"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "protocol-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/protocol-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":37,"category":"temperature-mapping","documentId":"p4xwbmbf27v1oaubxwqaa0dm"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "terms-and-conditions",
    path: pageZB1m7v8C5YMeta?.path ?? "/terms-and-conditions/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":28,"category":"","documentId":"a16s0g9qjc9eonrnp8lf47zg"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "automating-temperature-compliance-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/automating-temperature-compliance-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":41,"category":"regulations-temperature-compliance","documentId":"celqvw6f2diy7ssjpkceuaoc"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "challenges-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-calibration/challenges-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":42,"category":"temperature-calibration","documentId":"qwpn310nam6vhv4u77rxwnnq"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "hidden-costs-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/hidden-costs-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":76,"category":"regulations-temperature-compliance","documentId":"f6c31puonf3jbuwkdclcj33l"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "utilize-data-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/utilize-data-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":38,"category":"temperature-mapping","documentId":"l55dy8uz6donh89w1pt6ryxu"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ceiv-pharma-guide",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/ceiv-pharma-guide/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":85,"category":"regulations-temperature-compliance","documentId":"oeafs8uw3x0tdnopipfsq9yk"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ceiv",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/ceiv/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":83,"category":"regulations-temperature-compliance","documentId":"px9bj8clr0zhmy2orkfurz3p"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you-post-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you-post-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":53,"category":"","documentId":"rjp0g8f2x8i6o15md1fa33j1"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mapping-price",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mapping-price/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":23,"category":"temperature-mapping","documentId":"a13vi6iqn5gdc0kdo53fsglr"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "partners",
    path: pageZB1m7v8C5YMeta?.path ?? "/partners/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":14,"category":"","documentId":"dohadmttoes5vlizaix77jy3"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "21-cfr-part-11-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":43,"category":"regulations-temperature-compliance","documentId":"san6w68mps2orghgidv5mo6n"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you-post-mapping-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you-post-mapping-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":91,"category":"","documentId":"exdl1jeawbc6aipghbwsyxah"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":72,"category":"","documentId":"huo5719t8s87zgcl0eo895oc"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "thank-you-w",
    path: pageZB1m7v8C5YMeta?.path ?? "/thank-you-w/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":71,"category":"","documentId":"ri64x3ohlk87ffzpnhzngfme"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "21-cfr-part-11-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":55,"category":"regulations-temperature-compliance","documentId":"x6xljm3yrebk5pz72txq7qqy"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-a-talk",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-a-talk/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":75,"category":"","documentId":"mh7nu6nvop481t5866wa401o"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/our-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":27,"category":"","documentId":"bxt1jqrrx8eh7ezbilqn5156"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "contact",
    path: pageZB1m7v8C5YMeta?.path ?? "/contact/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":460,"category":"","documentId":"vf0g15ya08cnfkd9x39lp4l6"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-demo",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-demo/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":8,"category":"","documentId":"fvmhzywvqtjao6co5nem5u9z"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "unify-temperature-compliance",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/unify-temperature-compliance/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":34,"category":"regulations-temperature-compliance","documentId":"y2aqthowppvotib2swdm8cxq"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "eliminate-gaps-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/eliminate-gaps-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":77,"category":"regulations-temperature-compliance","documentId":"alvpi39vba840tonwzotusqr"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "webinar-sign-up",
    path: pageZB1m7v8C5YMeta?.path ?? "/webinar-sign-up/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":80,"category":"","documentId":"rrwmq76n9y7zk70d818w4gt3"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "digital-tool-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/digital-tool-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":84,"category":"regulations-temperature-compliance","documentId":"vbxmclr7aah23mua7lihvm8x"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mistakes-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mistakes-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":61,"category":"temperature-mapping","documentId":"wpfnz8l8mgx4udoriexbr951"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ultimate-guide",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/ultimate-guide/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":54,"category":"temperature-mapping","documentId":"o35d0k9dqf06mqvsp73dfx0e"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "test",
    path: pageZB1m7v8C5YMeta?.path ?? "/test/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":465,"category":"","documentId":"yw4ocbhw3effr2vkwwz3irwc"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "report-template",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/report-template/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":59,"category":"temperature-mapping","documentId":"xsv4h5yps0dr0zss28wy0sh3"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mistakes-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mistakes-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":36,"category":"temperature-mapping","documentId":"iiyimrstgzjwbx7wgnccgqlt"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "ultra-low-freezers",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/ultra-low-freezers/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":46,"category":"data-logger","documentId":"pi7ivdyldtfl28yr3fdv8x8s"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "catalog",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/catalog/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":87,"category":"temperature-mapping","documentId":"zdf1ltgpuh4i8yb5egen7vux"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "gacp-gmp-cannabis",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/gacp-gmp-cannabis/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":108,"category":"regulations-temperature-compliance","documentId":"x0nja0ptiz34ty998ylttcv9"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-mapping",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":15,"category":"","documentId":"ukcy100avqgx5mr1ont0xmcg"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "adopt-fda-gdp",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/adopt-fda-gdp/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":98,"category":"regulations-temperature-compliance","documentId":"x343jnaxgiopebsveg7chw76"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "frequent-issues-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/frequent-issues-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":90,"category":"regulations-temperature-compliance","documentId":"i7t02fk8s0jckfqtoe62fjo9"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "catalog-holmhalby",
    path: pageZB1m7v8C5YMeta?.path ?? "/catalog-holmhalby/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":103,"category":"","documentId":"b91ixrpvirbfkh9kren1gdwg"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "WHO-guidelines-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/WHO-guidelines-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":89,"category":"temperature-mapping","documentId":"rluc86viih04qjs9ps63sqa5"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "who-checklist",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/who-checklist/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":94,"category":"temperature-mapping","documentId":"jp7ea7206b9g5yhzloinneeg"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-price-talk",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-price-talk/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":484,"category":"","documentId":"t9d8dy3orghtupe42w41bx2y"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "who-mapping-guidelines",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/who-mapping-guidelines/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":95,"category":"temperature-mapping","documentId":"a3uzxchc98jzkfy5w7ns0a0o"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mapping-mistakes-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mapping-mistakes-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":109,"category":"temperature-mapping","documentId":"mxj2jqftxedggqy7gw19w5sy"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "certifications-accreditations",
    path: pageZB1m7v8C5YMeta?.path ?? "/certifications-accreditations/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":104,"category":"","documentId":"gcv0bmvctyudlc364fm7816f"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-sensors",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/our-sensors/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":114,"category":"data-logger","documentId":"z07zshmmgivkdk1sxm91sm0f"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "eagle-air-freight",
    path: pageZB1m7v8C5YMeta?.path ?? "/cases/eagle-air-freight/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":408,"category":"cases","documentId":"pr8c58iox7rn2pr8vt6lvv18"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-monitoring-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/our-monitoring-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":415,"category":"temperature-monitoring","documentId":"k5gb9n32h69wky34noi5ga10"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-mapping-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/our-mapping-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":416,"category":"temperature-mapping","documentId":"locgq2mrxdhseiqwui4mr7cy"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "book-a-service-talk",
    path: pageZB1m7v8C5YMeta?.path ?? "/book-a-service-talk/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":426,"category":"","documentId":"qssd5thm24ieppbycbteenkc"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "consultancy",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/consultancy/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":431,"category":"temperature-mapping","documentId":"ctzcrw9bhddllhut2b70fux1"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "FDA-21-cfr-part-11-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/FDA-21-cfr-part-11-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":106,"category":"regulations-temperature-compliance","documentId":"vnshe39hq3ryppfbgr6q33uw"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "winter-mapping-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/winter-mapping-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":101,"category":"temperature-mapping","documentId":"aypk007cgrvdwryd3j3m00hf"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "gxp-protocols-webinar",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/gxp-protocols-webinar/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":107,"category":"temperature-mapping","documentId":"c7ynetlnszgi6kxsccebsoeu"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "guide-reduce-work",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/guide-reduce-work/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":405,"category":"regulations-temperature-compliance","documentId":"gztnfgabgai8gue5lgz81d7f"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "data-logger",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":413,"category":"","documentId":"l2egx32vzsd9hcab9nxtxabt"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "gxp",
    path: pageZB1m7v8C5YMeta?.path ?? "/regulations-temperature-compliance/gxp/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":467,"category":"regulations-temperature-compliance","documentId":"se2wxgl031rv2n5ufkli5bxj"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-data-logger-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/data-logger/our-data-logger-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":419,"category":"data-logger","documentId":"feghcoclue3jj8a9pp5bhuh8"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "temperature-monitoring",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-monitoring/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":468,"category":"","documentId":"zgs1it3mpr2twzdc9i6vhdnn"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "mapping-buyers-guide",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/mapping-buyers-guide/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":369,"category":"temperature-mapping","documentId":"jtf6tm4i77pfuaechg59ctbg"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "our-continuous-mapping-solution",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/our-continuous-mapping-solution/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":423,"category":"temperature-mapping","documentId":"n9l65c2ku11fdf6xjs9ep3fe"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "summer-mapping-in-gxp",
    path: pageZB1m7v8C5YMeta?.path ?? "/temperature-mapping/summer-mapping-in-gxp/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":447,"category":"temperature-mapping","documentId":"q1mwu1qm09gdzdi8gfma50gt"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: pageZB1m7v8C5YMeta?.name ?? "service-level-agreements",
    path: pageZB1m7v8C5YMeta?.path ?? "/service-level-agreements/",
    meta: { ...(pageZB1m7v8C5YMeta || {}), ...{"ID":523,"category":"","documentId":"a3twp5tuq6v4g9zrutzepcxs"} },
    alias: pageZB1m7v8C5YMeta?.alias || [],
    redirect: pageZB1m7v8C5YMeta?.redirect,
    component: () => import("~/strapi-pages/page.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "compliance-solutions",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/compliance-solutions/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":6,"category":"regulations-temperature-compliance","documentId":"kb1a0r30ufqwczpdiyieynba"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "protocol",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/protocol/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":98,"category":"temperature-mapping","documentId":"njaouljhzavdqsoe11d0eew4"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "8-calibration-components",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-calibration/8-calibration-components/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":20,"category":"temperature-calibration","documentId":"tayvs95mdggnq8krj176amau"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "summer-mapping",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/summer-mapping/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":126,"category":"temperature-mapping","documentId":"xdw9ks5tys8ax6lgymfddlhn"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "choosing-data-loggers",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/choosing-data-loggers/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":136,"category":"temperature-mapping","documentId":"jec5yg082kq6d8zffknnr1b3"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "analyze-data-from-mapping-exercise",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/analyze-data-from-mapping-exercise/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":138,"category":"temperature-mapping","documentId":"cfpzaiapnzav9o9658vod1ge"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "mapping-validation-difference",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/mapping-validation-difference/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":143,"category":"temperature-mapping","documentId":"qcbst6i1k4bh24do8zi0e8jp"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "controling-temperature-in-your-cooling-equipement-during-summer",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-monitoring/controling-temperature-in-your-cooling-equipement-during-summer/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":24,"category":"temperature-monitoring","documentId":"c76c1zicywzielc3mos0yvvl"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "FDA-inspections-in-pharma",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/FDA-inspections-in-pharma/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":34,"category":"regulations-temperature-compliance","documentId":"yb4pc91vluiem86z9c786248"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "unify-compliance",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/unify-compliance/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":8,"category":"regulations-temperature-compliance","documentId":"c9wqchm78nglke7cliq8oail"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "eupry-dtu-vet",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/eupry-dtu-vet/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":29,"category":"news","documentId":"zsc85d7395fyz5h9pb3r45ad"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "temperature-qualification",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/temperature-qualification/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":32,"category":"temperature-mapping","documentId":"e79la1fm4bhj4zmzv52shbqi"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "eupry-receives-e-2-4m-investment-from-the-danish-growth-fund-and-ugly-duckling-ventures",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/eupry-receives-e-2-4m-investment-from-the-danish-growth-fund-and-ugly-duckling-ventures/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":27,"category":"news","documentId":"uvcsth5r4y7jz8p0qepspw6b"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "why-is-calibration-important",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-calibration/why-is-calibration-important/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":19,"category":"temperature-calibration","documentId":"h9f85uvueyto3ucrjj7t77ii"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "iso-17025",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/iso-17025/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":5,"category":"regulations-temperature-compliance","documentId":"awmj1m43g7lxjl7hyd6je5mw"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "calibration-solutions",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-calibration/calibration-solutions/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":21,"category":"temperature-calibration","documentId":"td3dh8j44cthhvh20l5p24ha"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "what-you-need-to-know-about-temperature-data-loggers",
    path: blogPostlYsAxC8GClMeta?.path ?? "/data-logger/what-you-need-to-know-about-temperature-data-loggers/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":22,"category":"data-logger","documentId":"dq476w6y1i35q4j37qxf5foe"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "winter-mapping",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/winter-mapping/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":31,"category":"temperature-mapping","documentId":"p4p4ixxoy0tot76a3tx0n73m"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "key-players-for-temperature-mapping",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/key-players-for-temperature-mapping/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":10,"category":"temperature-mapping","documentId":"hk9lc9krmd1qdbges62rgte9"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "21-cfr-part-11",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":4,"category":"regulations-temperature-compliance","documentId":"rhcpbpy1dvrdzf25jdyepuof"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "2024-investment",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/2024-investment/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":36,"category":"news","documentId":"vb9g2iqk20aatlo8sz1n269m"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "21-cfr-part-11-compliant-temperature-monitoring-systems",
    path: blogPostlYsAxC8GClMeta?.path ?? "/regulations-temperature-compliance/21-cfr-part-11-compliant-temperature-monitoring-systems/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":12,"category":"regulations-temperature-compliance","documentId":"pz5c1zy4vqzudqsj4155vdki"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "usb-data-logger",
    path: blogPostlYsAxC8GClMeta?.path ?? "/data-logger/usb-data-logger/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":15,"category":"data-logger","documentId":"mf2p2ers4bj7wuiebuyzqx4s"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "danish-technological-institute-eupry-are-collaborating-on-digital-calibration-certificates",
    path: blogPostlYsAxC8GClMeta?.path ?? "/news/danish-technological-institute-eupry-are-collaborating-on-digital-calibration-certificates/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":28,"category":"news","documentId":"mn1ndzq1m0amazfotv05o9ph"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "using-a-mapping-kit",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/using-a-mapping-kit/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":14,"category":"temperature-mapping","documentId":"gtle7gpzeajup026fcfy0vxm"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "why-is-the-temperature-fluctuating-in-my-cooling-equipment",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-monitoring/why-is-the-temperature-fluctuating-in-my-cooling-equipment/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":18,"category":"temperature-monitoring","documentId":"tdwfxlayj61598t3n1tjg5jj"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "continuous-temperature-mapping",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/continuous-temperature-mapping/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":139,"category":"temperature-mapping","documentId":"betu6ji1v865xa5i6a7r6oc5"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "risk-based",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/risk-based/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":140,"category":"temperature-mapping","documentId":"ksu9gw3i7u8417bnkdukqcsb"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "training",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/training/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":141,"category":"temperature-mapping","documentId":"zifpnxbyhfsosnlg7yr70d4d"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "equipment",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/equipment/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":142,"category":"temperature-mapping","documentId":"nzpnar5tynuols1b8wwsmog3"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "manual-to-digital",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/manual-to-digital/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":135,"category":"temperature-mapping","documentId":"pbzio08jgulczerj86st3i6k"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: blogPostlYsAxC8GClMeta?.name ?? "guidelines-pharmaceutical",
    path: blogPostlYsAxC8GClMeta?.path ?? "/temperature-mapping/guidelines-pharmaceutical/",
    meta: { ...(blogPostlYsAxC8GClMeta || {}), ...{"ID":144,"category":"temperature-mapping","documentId":"s76q1ckwbytzn8303vczfn69"} },
    alias: blogPostlYsAxC8GClMeta?.alias || [],
    redirect: blogPostlYsAxC8GClMeta?.redirect,
    component: () => import("~/strapi-pages/blogPost.vue").then(m => m.default || m)
  },
  {
    name: caseHuIuXghG5PMeta?.name ?? "wfs",
    path: caseHuIuXghG5PMeta?.path ?? "/cases/wfs/",
    meta: { ...(caseHuIuXghG5PMeta || {}), ...{"ID":14,"category":"cases","documentId":"utx9fm5ir1wxcwv09jpxban4"} },
    alias: caseHuIuXghG5PMeta?.alias || [],
    redirect: caseHuIuXghG5PMeta?.redirect,
    component: () => import("~/strapi-pages/case.vue").then(m => m.default || m)
  },
  {
    name: caseHuIuXghG5PMeta?.name ?? "genera-research",
    path: caseHuIuXghG5PMeta?.path ?? "/cases/genera-research/",
    meta: { ...(caseHuIuXghG5PMeta || {}), ...{"ID":4,"category":"cases","documentId":"zp8l615yomzdc1szwscrg6ob"} },
    alias: caseHuIuXghG5PMeta?.alias || [],
    redirect: caseHuIuXghG5PMeta?.redirect,
    component: () => import("~/strapi-pages/case.vue").then(m => m.default || m)
  },
  {
    name: caseHuIuXghG5PMeta?.name ?? "dsv-sweden",
    path: caseHuIuXghG5PMeta?.path ?? "/cases/dsv-sweden/",
    meta: { ...(caseHuIuXghG5PMeta || {}), ...{"ID":13,"category":"cases","documentId":"haa90cg4l6n95hrhvpd4icxi"} },
    alias: caseHuIuXghG5PMeta?.alias || [],
    redirect: caseHuIuXghG5PMeta?.redirect,
    component: () => import("~/strapi-pages/case.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-devices",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-devices/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":299,"category":"lp","documentId":"u28uv46vpgnmuks26oibrymx"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-humidity-sensor",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-humidity-sensor/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":63,"category":"lp","documentId":"l77crlba0bjmonv8098029x0"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "data-logger-calculator",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/data-logger-calculator/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":197,"category":"lp","documentId":"tdjrji24e4tje7e1uv9niw1j"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "digital-mapping-checklist",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/digital-mapping-checklist/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":311,"category":"lp","documentId":"dqsh0xik6wqg7sz0zg3dtbo7"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "wireless-temperature-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/wireless-temperature-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":276,"category":"","documentId":"mulh5k7o208lqcm1o6dg0war"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-logistics",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-logistics/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":216,"category":"lp","documentId":"puqkjrt0xqpt10rmkip7hne1"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "vaccine-temperature-data-loggers",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/vaccine-temperature-data-loggers/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":64,"category":"lp","documentId":"b3tl42rf9uzyi33jqzvnprxf"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-biotech",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-biotech/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":45,"category":"lp","documentId":"mjtopfe6ptefldmh6ystsmxr"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "facilities-management-monitoring-system",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/facilities-management-monitoring-system/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":66,"category":"lp","documentId":"wl7ab1ypvuejmwxn3hxk0kwg"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "winter-temperature-mapping-standard",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/winter-temperature-mapping-standard/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":5,"category":"lp","documentId":"hc7zas042qdmpqj5n23aw4g8"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "alternative-to-controlant",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/alternative-to-controlant/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":7,"category":"lp","documentId":"gonnrmxhqalg8c2roclmhrm3"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-monitoring-de",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-monitoring-de/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":13,"category":"lp","documentId":"r9chovbsmp6mz8b2fnzk0rmm"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "standard-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/standard-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":30,"category":"lp","documentId":"xgs786go5qgldvp0krajph6d"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "standard-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/standard-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":12,"category":"lp","documentId":"bzq7iab1536mxzz3xy47g9xv"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-device-de",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-device-de/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":14,"category":"lp","documentId":"ucrsun9swzsqtjvhvbu8kqk6"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "summer-temperature-mapping-simple",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/summer-temperature-mapping-simple/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":55,"category":"lp","documentId":"hgqt12yr7r0rs7tgx7br11wn"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-kit",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/temperature-mapping-kit/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":18,"category":"temperature-mapping","documentId":"kt27o91w7r21bbh6nokjd50e"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "scada-wireless-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/scada-wireless-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":67,"category":"lp","documentId":"cm4yjm2fypebgacss3nwq6m9"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-temperature-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-temperature-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":6,"category":"lp","documentId":"n6itxgs7auu5303pz2wv0dhi"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "warehouse-temperature-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/warehouse-temperature-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":19,"category":"temperature-mapping","documentId":"norxliq9zt6pvkdktidtryt7"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "summer-temperature-mapping-standard",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/summer-temperature-mapping-standard/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":54,"category":"lp","documentId":"szy57ol98ztmr2yp4gktgc42"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "humidity-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/humidity-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":31,"category":"lp","documentId":"tn0nl2ruz68tufgua3tlfl7f"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "product-catalog-de",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/product-catalog-de/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":3,"category":"lp","documentId":"c5jsjbqqtfqk5bb8wx4dj54w"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-device",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-monitoring-device/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":20,"category":"","documentId":"qqjnpnzx8k4wsi5qolhfo49c"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-lp",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-lp/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":10,"category":"lp","documentId":"o1o7l2rttu6mt5nua6poqw36"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "dsv-logistics-case",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/dsv-logistics-case/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":57,"category":"temperature-mapping","documentId":"tsymt1iiccf37lyjc28a8ib0"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "calibration",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/calibration/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":2,"category":"lp","documentId":"hxqnmmkcjegdkgkf64utpjce"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperatur-monitorering",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperatur-monitorering/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":22,"category":"lp","documentId":"tcmclf7qapaz29gbudy1ykg7"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-logistics",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-logistics/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":43,"category":"lp","documentId":"yp14aeg2qgev38xwm2z7swod"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "iq-oq-pq",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/iq-oq-pq/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":68,"category":"temperature-mapping","documentId":"u6k4ma7meeokp6q1jz6go5d2"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":15,"category":"lp","documentId":"ogmz11fq8y5m0j6c1qsrgt86"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "da",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/da/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":21,"category":"","documentId":"io9kvj96g89ic47xsun4gx9o"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-warehouse",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-warehouse/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":47,"category":"lp","documentId":"n796ec6y80oh8f64bzxyl4kq"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "1BVt0oRrDTUO3Khvoeu0E1gfk989",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/1BVt0oRrDTUO3Khvoeu0E1gfk989/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":58,"category":"","documentId":"ptvqvvr5pbg1piclhbt56kvw"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-biotech",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-biotech/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":50,"category":"lp","documentId":"vbsc9fq0jpnv94gz0sb48c1j"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-for-pharma",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-for-pharma/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":48,"category":"lp","documentId":"v51z0no7izru92d214gaf667"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "winter-temperature-mapping-simple",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/winter-temperature-mapping-simple/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":9,"category":"lp","documentId":"x0srg1vlmyl645m69rtuzoa2"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-warehouse",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-warehouse/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":290,"category":"lp","documentId":"je7f3os0k1g7x8u9kkduz8al"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-recorder",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-recorder/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":247,"category":"lp","documentId":"w8ls8010ymhzr4wws4329ynm"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "freezer-temperature-alarms",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/freezer-temperature-alarms/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":300,"category":"lp","documentId":"op06s356aap2x5ayns98oiuz"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-probe",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-probe/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":312,"category":"lp","documentId":"fio282n3fy2crbum250kgeb4"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-mapping-buyer-guideline",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-mapping-buyer-guideline/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":201,"category":"lp","documentId":"chdy0y56w91jbd8cf8l8hgct"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-pharma",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-pharma/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":292,"category":"lp","documentId":"e6v1an3yqbtec0baybm1ygvt"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-monitoring-for-laboratories",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-monitoring-for-laboratories/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":294,"category":"lp","documentId":"ji4wpov1o91x0hrdmr97pq8j"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "wireless-temperature-data-loggers",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/wireless-temperature-data-loggers/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":296,"category":"lp","documentId":"thadvzgmk2waq10v0sc1c62m"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-humidity-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-humidity-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":298,"category":"lp","documentId":"vkj81or6rcbw13rlz6pmqzd2"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "logistics",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/industries/logistics/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":313,"category":"industries","documentId":"srzpyzjh8jbmdqy2bzxn5kr0"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "simple-temperature-monitoring",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/simple-temperature-monitoring/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":293,"category":"lp","documentId":"dbzjcbx6xq0u2gjs8q4opage"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-humidity-data-logger",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-humidity-data-logger/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":295,"category":"lp","documentId":"ddqcy7xbkxwjn297fmfy4vlk"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "biotech",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/industries/biotech/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":179,"category":"industries","documentId":"ebbrzxj57oxjsa5su08j5wx8"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "logistics-reefer-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/logistics-reefer-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":171,"category":"temperature-mapping","documentId":"dqbpwjwafrgomfdmr3rx0x32"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "laboratories",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/industries/laboratories/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":180,"category":"industries","documentId":"xrvmr9j5m721p2lruqebjkpx"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "temperature-alarms",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/lp/temperature-alarms/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":254,"category":"lp","documentId":"gsqtmg2uh7nmgz9aaatltxu7"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  },
  {
    name: landingPage3xsCkfWs9IMeta?.name ?? "logistics-truck-mapping",
    path: landingPage3xsCkfWs9IMeta?.path ?? "/temperature-mapping/logistics-truck-mapping/",
    meta: { ...(landingPage3xsCkfWs9IMeta || {}), ...{"ID":314,"category":"temperature-mapping","documentId":"u1i5zlgnyns72i6rdj9bsmy5"} },
    alias: landingPage3xsCkfWs9IMeta?.alias || [],
    redirect: landingPage3xsCkfWs9IMeta?.redirect,
    component: () => import("~/strapi-pages/landingPage.vue").then(m => m.default || m)
  }
]